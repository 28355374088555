import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { exhaustMap, take } from 'rxjs/operators';
import { UserModel } from '../models/user-model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.userInfo.pipe(
            take(1),
            exhaustMap((userModel: UserModel) => {
                if (!userModel) {
                    return next.handle(req);
                }
                const modifiedReq = req.clone({
                    headers: req.headers.set('X-Custom-Authorization', 'Bearer ' + userModel?.token)
                })
                return next.handle(modifiedReq);
            })
        )

    }
}