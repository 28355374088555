import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

    public isAuthorized = false;

    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.authService.userInfo.subscribe((user) => {
            this.isAuthorized = user !== null;
            if (!this.isAuthorized) {
                this.router.navigate(['/login']);
            }
        }, (error) => {
            this.isAuthorized = false;
            this.router.navigate(['/login']);
        })
    }
}