import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { map, tap } from 'rxjs/operators';
import { LoginModel } from '../models/login';
import { MicroftProfileInfo } from '../models/MicroftProfileInfo';
import { UserModel } from '../models/user-model';

@Injectable({
  providedIn: 'root'
})
export class MsSigninService {

  static msClientId = 'ca12ddb4-66e1-4b8e-9843-551d020659a2'
  static readonly MS_GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
  static readonly AUTHENTICATE_MS_ENDPOINT = 'api/AuthenticateMS';
  static readonly REGISTER_USER_MS_ENDPOINT = 'api/RegisterUserMS';

  constructor(
    private httpClient: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService
  ) { }

  login() {
    return this.signinToMicrosoft()
      .pipe(
        map(
          item => {
            return this.readMicrosoftProfileInfo();
          }))
  }

  logOut() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.msalService.logoutPopup({
          postLogoutRedirectUri: "/",
          mainWindowRedirectUri: "/"
        });
      } else {
        this.msalService.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      }
    }
  }

  AuthenticateByMicrosoftId(info: MicroftProfileInfo) {
    const loginModel: LoginModel =
    {
      userName: info.userPrincipalName,
      password: null,
      microsoftId: info.id,
      googleId: null
    }
    return this.httpClient.post<UserModel>(MsSigninService.AUTHENTICATE_MS_ENDPOINT, loginModel)

  }

  private signinToMicrosoft() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        return this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .pipe(
            tap((response: AuthenticationResult) => {
              this.msalService.instance.setActiveAccount(response.account);
            })
          );
      } else {
        this.msalService.loginPopup()
          .pipe(
            tap((response: AuthenticationResult) => {
              this.msalService.instance.setActiveAccount(response.account);
            })
          );
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  private readMicrosoftProfileInfo() {
    return this.httpClient.get<MicroftProfileInfo>(MsSigninService.MS_GRAPH_ENDPOINT)
    /*.pipe(
      tap((profile) => {
        console.log(profile);
      })
    );*/
  }

}
