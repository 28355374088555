import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginModel } from '../models/login';
import { RegistrationModel } from '../models/registration';
import { UserModel } from '../models/user-model';
import { GoogleSigninService } from './google-signin.service';
import { MsSigninService } from './ms-signin.service';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static readonly MS_GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

  static readonly AUTHENTICATE_USER_ENDPOINT = 'api/AuthenticateUser';
  static readonly REGISTER_USER_ENDPOINT = 'api/RegisterUser';

  userInfo = new BehaviorSubject<UserModel>(null);

  surveyIdFromUrl: string = "";

  constructor(
    private httpClient: HttpClient,
    private msSigninService: MsSigninService,
    private googleSigninService: GoogleSigninService,
    private router: Router) {
    let surveyId: string = localStorage.getItem('surveyIdFromUrl');
    if (surveyId) {
      //console.log(surveyId);
      this.surveyIdFromUrl = surveyId;
    }
  }

  logIn(loginModel: LoginModel) {
    return this.httpClient.post<UserModel>(AuthService.AUTHENTICATE_USER_ENDPOINT, loginModel).pipe(
      tap((userModel: UserModel) => {
        this.handleAuthenticatedUser(userModel);
      })
    );
  }


  logOut() {
    this.userInfo.next(null);
    localStorage.setItem('userData', JSON.stringify(null));
    localStorage.removeItem('surveyIdFromUrl');
    this.logOutWithMicrosoft();
    this.router.navigate(['/login']);
  }

  register(registrationModel: RegistrationModel, googleToken?: string) {
    var endpoint: string = AuthService.REGISTER_USER_ENDPOINT;
    //console.log(registrationModel)
    if (registrationModel.UserInfo.googleId != null && registrationModel.UserInfo.googleId != '') {
      endpoint = GoogleSigninService.REGISTER_USER_GOOGLE_ENDPOINT;
      return this.httpClient.post<UserModel>(
        endpoint,
        registrationModel,
        {
          headers: { 'X-Custom-Authorization': 'Bearer ' + googleToken }
        })
        .pipe(
          tap((userModel: UserModel) => {
            this.handleAuthenticatedUser(userModel);
          })
        );
    }

    if (registrationModel.UserInfo.microsoftId != null && registrationModel.UserInfo.microsoftId != '') {
      endpoint = MsSigninService.REGISTER_USER_MS_ENDPOINT;
    }
    return this.httpClient.post<UserModel>(endpoint, registrationModel).pipe(
      tap((userModel: UserModel) => {
        this.handleAuthenticatedUser(userModel);
      })
    );
  }

  getFromLocalStorage() {
    const userModel: UserModel = JSON.parse(localStorage.getItem('userData'))
    if (userModel) {
      this.userInfo.next(userModel);
    }
  }

  handleAuthenticatedUser(userModel: UserModel) {

    localStorage.setItem('userData', JSON.stringify(userModel));
    this.userInfo.next(userModel);

    if (userModel.role === 'admin') {
      this.router.navigate(['administration']);
    }
    else {
      this.router.navigate([this.surveyIdFromUrl]);
    }
  }

  logInWithMicrosoft() {
    this.userInfo.next(null);
    return this.msSigninService.login()
  }

  logOutWithMicrosoft() {
    this.msSigninService.logOut()
  }

  logInWithGoogle() {
    this.userInfo.next(null);
    return this.googleSigninService.login()
  }

  logOutWithGoogle() {
    this.googleSigninService.logOut()
  }

}
