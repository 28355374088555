import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GoogleProfileInfo } from 'src/app/models/GoogleProfileInfo';
import { RegistrationModel } from '../../models/registration';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  @ViewChild('form') form: NgForm;

  passwordDisabled = false;
  fieldsDisabled: boolean = false;
  registrationLoading: boolean = false;
  ms_loginLoading: boolean = false;
  g_loginLoading: boolean = false;
  googleUser: GoogleProfileInfo;

  constructor(
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    private router: Router
  ) {
    //console.log('RegistrationComponent.constructor')
  }

  ngOnInit(): void {
  }

  private unlockUI() {
    this.fieldsDisabled = false;
    this.registrationLoading = false
    this.ms_loginLoading = false;
    this.g_loginLoading = false;
    this.ref.detectChanges();
  }

  onSubmit(registerForm: NgForm) {
    //console.log('*** 2')
    if (registerForm.valid) {
      this.registrationLoading = true;
      this.fieldsDisabled = true;
      const registrationModel: RegistrationModel = registerForm.value;
      //console.log(registrationModel)
      let googleIdToken: string = null;
      if (this.googleUser)
        googleIdToken = this.googleUser.credentials;
      this.authService.register(registrationModel, googleIdToken).subscribe(
        res => this.unlockUI(),
        error => {
          this.unlockUI()
        })
    }
  }

  logInAsMicrosoft() {
    //console.log('*** 1')
    this.passwordDisabled = false;
    this.ms_loginLoading = true;
    this.fieldsDisabled = true;
    this.ref.detectChanges();

    this.authService.logInWithMicrosoft()
      .subscribe(profile => {
        profile
          .subscribe(
            info => {
              //console.log(this.form);
              var userInfo: FormGroup = <any>(this.form.controls['UserInfo']);
              userInfo.controls["userName"].setValue(info.userPrincipalName);
              userInfo.controls["microsoftId"].setValue(info.id);
              this.passwordDisabled = true;
              this.unlockUI();
            },
            error => {
              this.unlockUI();
            }
          );
      }, error => {
        this.unlockUI();
      })
  }

  logInAsGoogle() {
    this.passwordDisabled = false;
    this.g_loginLoading = true;
    this.fieldsDisabled = true;
    this.ref.detectChanges();
    this.authService.logInWithGoogle()
      .subscribe(
        info => {
          if (info) {
            //console.log(this.form);
            var userInfo: FormGroup = <any>(this.form.controls['UserInfo']);
            userInfo.controls["userName"].setValue(info.userPrincipalName);
            userInfo.controls["googleId"].setValue(info.id);
            this.passwordDisabled = true;
          }
          this.googleUser = info;
          this.unlockUI();
        },
        error => {
          this.unlockUI();
        }
      );
  }

  preventFromSomeCharacters(event) {
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  }
}
