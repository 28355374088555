import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import Swal from 'sweetalert2'

import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {

                var messageText: string;
                if (typeof (error.error) == 'string') {
                    messageText = error.error;
                }
                else {
                    messageText = error.message;
                }

                Swal.fire({
                    title: 'Oops, something went wrong!',
                    confirmButtonColor: '#0D6EFD',
                    text: messageText,
                    icon: 'error',
                    // confirmButtonText: 'Cool'
                })
                return throwError(error);
            })
        );
    }
}