import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InfoComponent } from './app-components/info/info.component';
import { LoginComponent } from './app-components/login/login.component';
import { RegistrationComponent } from './app-components/registration/registration.component';
import { SurveyAnswersComponent } from './app-components/survey-answers/survey-answers.component';
import { UserWrapperComponent } from './app-components/user-wrapper/user-wrapper.component';

import { AuthGuard } from './guards-interceptors/auth.guard';

const routes: Routes = [
  { path: 'registration', component: RegistrationComponent },
  { path: 'login', component: LoginComponent },
  
  {
    path: '', component: UserWrapperComponent, canActivate: [AuthGuard],
    children: [
      { path: 'info', component: InfoComponent },
      { path: ':id', component: SurveyAnswersComponent },
      { path: '**', redirectTo: '/info' }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
