import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import Swal from 'sweetalert2';
import { GoogleLWTPayLoad as GoogleJWTPayLoad, GoogleProfileInfo } from '../models/GoogleProfileInfo';
import { LoginModel } from '../models/login';
import { UserModel } from '../models/user-model';

declare var google: any;


@Injectable({
  providedIn: 'root'
})
export class GoogleSigninService {

  static googleClientId = "1061763847022-h6r8mcsptpgpofjeh4iqo24f0dui9rf0.apps.googleusercontent.com"
  static readonly AUTHENTICATE_GOOGLE_ENDPOINT = 'api/AuthenticateG';
  static readonly REGISTER_USER_GOOGLE_ENDPOINT = 'api/RegisterUserG';

  private userSubject = new ReplaySubject<GoogleProfileInfo>(1)

  user(): Observable<GoogleProfileInfo> {
    return this.userSubject.asObservable()
  }

  constructor(
    private httpClient: HttpClient,
    private zone: NgZone
  ) {
    const loc = window.location;
    const url = loc.protocol + "//" + loc.host + "/";
    //console.log(url);
    (<any>window).onGoogleLibraryLoad = () => {
      const IdConfiguration = {
        client_id: GoogleSigninService.googleClientId,
        state_cookie_domain: [url],
        callback: (resp) => { this.handleGgOneTap(resp) }
      }
      google.accounts.id.initialize(IdConfiguration)
    };
  }

  handleGgOneTap(resp) {
    if (resp != null) {
      const responsePayload = this.decodeJwtResponse(resp.credential);
      this.userSubject.next(responsePayload)
    }
  }

  decodeJwtResponse(credential: string): GoogleProfileInfo {
    var parts = credential.split('.');
    if (parts.length == 3) {
      let payload: GoogleJWTPayLoad = <any>JSON.parse(decodeURIComponent(escape(atob((parts[1])))));
      return {
        credentials: credential,
        id: payload.sub,
        givenName: payload.given_name,
        surname: payload.family_name,
        userPrincipalName: payload.email
      };
    }
    return null;
  }

  login() {
    return this.signin()
  }

  logOut() {
    this.signout()
  }

  private signin() {
    this.userSubject.next(null);
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        google.accounts.id.disableAutoSelect();
        if (notification.getNotDisplayedReason() == 'opt_out_or_no_session') {
          var messageText: string;
          messageText = "Google authentication flow require 3rd party cookies. This option seems to be disabled in your browser."
          Swal.fire({
            title: 'Google authentication',
            text: messageText,
            icon: 'info',
          })
          //throw messageText
          this.userSubject.error(messageText);
          return;
        }

        if (notification.getNotDisplayedReason() == 'browser_not_supported') {
          var messageText: string;
          messageText = "Google authentication flow is not supported by your browser."
          Swal.fire({
            title: 'Google authentication',
            text: messageText,
            icon: 'info',
          })
          //throw messageText
          this.userSubject.error(messageText);
          return;
        }

        if (notification.getNotDisplayedReason() == 'suppressed_by_user') {
          var messageText: string;
          messageText = "Google authentication was closed by user manualy. You can use Google credential after cooldown period ends. "
            + "More details at: https://developers.google.com/identity/gsi/web/guides/features#exponential_cooldown"
          Swal.fire({
            title: 'The сooldown period is not over yet',
            text: messageText,
            icon: 'info',
            showDenyButton: true,
            denyButtonText: "Reset cooldown period",
            confirmButtonText: "Ohm... really? Ok",

          }).then(res => {
            if (res.isDenied) {
              //this.cookieService.delete("cookie name", "/", "domain name", true, "None");
              document.cookie = "g_state=;expires=" + new Date(0).toUTCString()
            }
          });
          this.userSubject.error(messageText);
          return;
        }
        this.userSubject.error('closed');
      }
    });

    return this.user();
  }

  signout() {
    google.accounts.id.disableAutoSelect();
    this.userSubject.next(null);
  }

  public AuthenticateByGoogleId(info: GoogleProfileInfo) {
    const loginModel: LoginModel =
    {
      userName: info.userPrincipalName,
      password: null,
      microsoftId: null,
      googleId: info.id
    }
    const headers = new HttpHeaders(
      {
        'X-Custom-Authorization': 'Bearer ' + info.credentials
      }
    )

    return this.httpClient.post<UserModel>(
      GoogleSigninService.AUTHENTICATE_GOOGLE_ENDPOINT,
      loginModel,
      {
        headers: headers
      }
    )
  }

}
