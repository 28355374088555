import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RiskScoreGroup, SurveyWithAnswers } from '../models/survey';
import { UserAnswers } from '../models/user-answers';


@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private httpClient: HttpClient) { }

  getSurveyWithAnswersById(id: string): Observable<SurveyWithAnswers> {
    return this.httpClient.get<SurveyWithAnswers>('api/GetSurveyWithAnswersById?id=' + id, {});
  }

  saveUserAnswers(userAnswers: UserAnswers) {
    return this.httpClient.post('api/SaveAnswers', userAnswers);
  }

  getRiskScoreByAnswers(userAnswers: UserAnswers): Observable<RiskScoreGroup[]> {
    return this.httpClient.post<RiskScoreGroup[]>('api/GetRiskScore', userAnswers);
  }
}
