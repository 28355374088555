import { Component, NgZone, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { LoginModel } from 'src/app/models/login';
import { UserModel } from 'src/app/models/user-model';
import { GoogleSigninService } from 'src/app/services/google-signin.service';
import { MsSigninService } from 'src/app/services/ms-signin.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isAuthorized = false;

  fieldsDisabled: boolean = false;
  loginLoading: boolean = false;
  ms_loginLoading: boolean = false;
  google_loginLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private msService: MsSigninService,
    private googleService: GoogleSigninService,
    private zone: NgZone) {
    //console.log('LoginComponent.constructor')
  }


  ngOnInit() {
    this.authService.userInfo.subscribe(info => {
      if (info != null) {
        this.isAuthorized = true;
        this.unlockUI();
      } else {
        this.isAuthorized = false;
      }
    }, (error) => {
      this.unlockUI();
    })
  }

  private unlockUI() {
    this.fieldsDisabled = false;
    this.loginLoading = false
    this.ms_loginLoading = false;
    this.google_loginLoading = false;
  }

  onSubmit(loginForm: NgForm) {
    if (loginForm.valid) {
      this.loginLoading = true;
      this.fieldsDisabled = true;

      const loginModel: LoginModel = loginForm.value;
      this.authService.logIn(loginModel).subscribe(
        res => {
          this.unlockUI();
        },
        error => {
          this.unlockUI();
        }
      );
    }
  }

  logInAsMicrosoft() {
    this.ms_loginLoading = true;
    this.fieldsDisabled = true;
    this.authService.logInWithMicrosoft()
      .subscribe(profile => {
        profile.subscribe(
          info => {
            this.msService.AuthenticateByMicrosoftId(info)
              .pipe(
                tap((userModel: UserModel) => {
                  this.authService.handleAuthenticatedUser(userModel);
                })
              )
              .subscribe(
                res => {
                  this.unlockUI();
                },
                error => {
                  this.unlockUI();
                }
              );
          },
          error => {
            this.unlockUI();
          }
        );
      }, error => {
        this.unlockUI();
      })
  }

  logInAsGoogle() {
    this.google_loginLoading = true;
    this.fieldsDisabled = true;

    this.authService.logInWithGoogle()
      .subscribe(info => {
        if (info == null) return;
        this.googleService.AuthenticateByGoogleId(info)
          .subscribe(
            res => {
              this.zone.run(() => {
                this.authService.handleAuthenticatedUser(res);
                this.unlockUI();
              })
            },
            error => {
              this.zone.run(() => {
                this.unlockUI();
              })
            }
          );
      },
        error => {
          this.zone.run(() => {
            this.unlockUI();
          });
        });
  }
}
