<div *ngIf="isAuthorized == false">
    <app-spinner></app-spinner>
</div>

<div *ngIf="isAuthorized == true">

    <div class="holder">
        <h2>You have successfully logged in</h2>
        
        <span>Please use server URL to start survey</span>
    </div>
</div>