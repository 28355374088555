import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { debounce, switchMap, tap } from 'rxjs/operators';
import { RiskScoreGroup, SurveyWithAnswers } from 'src/app/models/survey';
import { UserAnswers } from 'src/app/models/user-answers';
import { AuthService } from 'src/app/services/auth.service';
import { SurveyService } from 'src/app/services/survey.service';
import * as SurveyLib from "survey-angular";

SurveyLib.StylesManager.applyTheme("default");

const myCss = {
  // body: 'bordered',
  navigation: {
    complete: 'stupidDocumentationOfThatFuckedSurveyJS, green-button'
  }
};

@Component({
  selector: 'app-survey-answers',
  templateUrl: './survey-answers.component.html',
  styleUrls: ['./survey-answers.component.scss']
})
export class SurveyAnswersComponent implements OnInit {
  saveAnswers$ = new Subject();
  onComplete$ = new Subject();

  surveyLoading: boolean = false;

  surveyId: number;
  riskScores: RiskScoreGroup[] = [];

  libSurveyModel: SurveyLib.SurveyModel;

  get userAnswers(): UserAnswers {
    return {
      surveyId: this.surveyId,
      answers: JSON.stringify(this.libSurveyModel?.data)
    }
  }

  get totalRiskScore(): number {
    return this.riskScores.map(score => score.riskScore).reduce((a, b) => a + b);
  }

  constructor(
    private surveyService: SurveyService,
    private authService: AuthService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.surveyLoading = true;
    this.surveyService.getSurveyWithAnswersById(this.authService.surveyIdFromUrl).subscribe((survey: SurveyWithAnswers) => {

      this.surveyId = survey.id;

      this.libSurveyModel = new SurveyLib.Model(JSON.stringify({
        ...JSON.parse(survey.surveyAsJson),
        completedHtml: "<span class='huge'>Thank you for completing the survey! 🎉</span class='huge'>"
      }));

      if (survey.answers) {
        this.libSurveyModel.data = JSON.parse(survey.answers);
      }

      this.libSurveyModel.onValueChanged.add(_ => this.saveAnswers$.next());
      this.libSurveyModel.onComplete.add(_ => this.onComplete$.next());

      SurveyLib.SurveyNG.render("surveyElement", { model: this.libSurveyModel, css: myCss });

      this.surveyLoading = false;

    }, error => this.surveyLoading = false);


    this.saveAnswers$.pipe(
      debounce(() => interval(5000)),
      switchMap(_ => this.surveyService.saveUserAnswers(this.userAnswers))
    ).subscribe();

    this.onComplete$.pipe(
      tap(_ => {
        //console.log("Comp")
        this.surveyLoading = true;
        this.ref.detectChanges();
        this.surveyService.getRiskScoreByAnswers(this.userAnswers).subscribe((riskScoreGroups: RiskScoreGroup[]) => {
          this.riskScores = riskScoreGroups;
          //console.log(this.riskScores);
          this.surveyLoading = false;
          this.ref.detectChanges();
        }, error => this.surveyLoading = false);
      })
    ).subscribe();
  }

  logger() {
    //console.log(this.riskScores);
  }
}
