import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let idParam = route.firstChild?.params['id'];
        if (idParam) {
            localStorage.setItem('surveyIdFromUrl', idParam);
            this.authService.surveyIdFromUrl = idParam;
        }

        return this.authService.userInfo.pipe(
            take(1),
            map(info => {
                const isAuth = !!info?.token;
                if (isAuth) {
                    return true;
                }
                else {
                    return this.router.createUrlTree(['/login']);
                }
            }));
    }

}