<form (ngSubmit)="onSubmit(form)" #form="ngForm">
  <img class="logo mb-3" src="../../../assets/logo.png" alt="Company logo">
  <div class="mb-3 text-left">
    <label for="userName">User name</label>
    <input type="text" class="form-control" id="userName" ngModel name="userName" required #userName="ngModel" [disabled]="fieldsDisabled">
    <span class="danger" *ngIf="(!userName.valid && userName.touched) || form.submitted">
      <span *ngIf="userName.errors?.required">
        User name is required
      </span>
    </span>
  </div>
  <div class="mb-4 text-left">
    <label for="password">Password</label>
    <input type="password" class="form-control" id="password"  ngModel name="password" required #password="ngModel" [disabled]="fieldsDisabled">
    <span class="danger" *ngIf="(!password.valid && password.touched) || form.submitted">
      <span *ngIf="password.errors?.required">
        Password is required
      </span>
    </span>
  </div>
  <button type="submit" class="btn btn-primary" [disabled]="loginLoading || fieldsDisabled || (form.submitted && !form.valid)">Sign in
    <span class="spinner-grow spinner-grow-sm mx-3"
          role="status"
          aria-hidden="true"
          *ngIf="loginLoading"></span>
  </button>

  <div class="or-line">
    <hr>
    <span>or</span>
  </div>

  <button class="btn btn-light social-button" [disabled]="fieldsDisabled" (click)="logInAsMicrosoft()">
    <img src="../../../assets/microsoft-logo.svg"
          alt="">
    <span class="spinner-grow spinner-grow-sm mx-3"
          role="status"
          aria-hidden="true"
          *ngIf="ms_loginLoading">
    </span>
  </button>
  <button class="btn btn-light social-button" [disabled]="fieldsDisabled" (click)="logInAsGoogle()">
    <img src="../../../assets/google-logo.svg"
          style="height: 23px;"
          alt="">
    <span class="spinner-grow spinner-grow-sm mx-3"
          role="status"
          aria-hidden="true"
          *ngIf="google_loginLoading">
    </span>
  </button>

  <div class="sign-up mt-4">
    <span>
      Don't have an account? 
      <a href="/registration"><b>Sign up</b></a>
    </span>
  </div>
</form>