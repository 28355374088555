<!-- <a class="btn btn-primary" href="/login">Log in</a> -->

<form (ngSubmit)="onSubmit(form)" #form="ngForm">
    <img class="logo mb-3" src="../../../assets/logo.png" alt="Company logo">

    <div ngModelGroup="UserInfo">
        <input type="hidden" id="microsoftId" ngModel name="microsoftId" #microsoftId="ngModel">
        <input type="hidden" id="googleId" ngModel name="googleId" #googleId="ngModel">
        <div class="mb-2 text-left">
            <label for="userName">User name</label>
            <input type="text" class="form-control" id="userName" ngModel name="userName" [disabled]="(fieldsDisabled)" required #userName="ngModel">
            <span class="danger" *ngIf="(!userName.valid && userName.touched) || form.submitted">
                <span *ngIf="userName.errors?.required">
                    User name is required
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="password">Password</label>
            <input type="text" class="form-control" id="password" ngModel name="password" [disabled]="(fieldsDisabled || passwordDisabled)" required minlength="5" #password="ngModel">
            <span class="danger" *ngIf="(!password.valid && password.touched) || form.submitted">
                <span *ngIf="password.errors?.required">
                    Password is required
                </span>
            <span *ngIf="password.errors?.minlength">
                    Password min length is 5
                </span>
            </span>
        </div>
    </div>

    <div ngModelGroup="CompanyInfo">
        <div class="mb-2 text-left">
            <label for="legalName">Company Legal Name</label>
            <input type="text" class="form-control" id="legalName" ngModel name="legalName" [disabled]="fieldsDisabled" required #legalName="ngModel">
            <span class="danger" *ngIf="(!legalName.valid && legalName.touched) || form.submitted">
                <span *ngIf="legalName.errors?.required">
                    Company legal name is required
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="businessName">Company Business name if different</label>
            <input type="text" class="form-control" id="businessName" ngModel name="businessName" [disabled]="fieldsDisabled" required #businessName="ngModel">
            <span class="danger" *ngIf="(!businessName.valid && businessName.touched) || form.submitted">
                <span *ngIf="businessName.errors?.required">
                    Company business name is required
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="industry">Company industry</label>
            <input type="text" class="form-control" id="industry" ngModel name="industry" [disabled]="fieldsDisabled" required #industry="ngModel">
            <span class="danger" *ngIf="(!industry.valid && industry.touched) || form.submitted">
                <span *ngIf="industry.errors?.required">
                    Company industry is required
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="address">Company address</label>
            <input type="text" class="form-control" id="address" ngModel name="address" [disabled]="fieldsDisabled" required #address="ngModel">
            <span class="danger" *ngIf="(!address.valid && address.touched) || form.submitted">
                <span *ngIf="address.errors?.required">
                    Company address is required
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="employeesCount">Number of employees</label>
            <input type="number" class="form-control" id="employeesCount" ngModel name="employeesCount" [disabled]="fieldsDisabled" required #employeesCount="ngModel" (keydown)="preventFromSomeCharacters($event)">
            <span class="danger" *ngIf="(!employeesCount.valid && employeesCount.touched) || form.submitted">
                <span *ngIf="employeesCount.errors?.required">
                    Number of employees is required
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="representativeName">Name of representative</label>
            <input type="text" class="form-control" id="representativeName" ngModel name="representativeName" [disabled]="fieldsDisabled" required #representativeName="ngModel">
            <span class="danger" *ngIf="(!representativeName.valid && representativeName.touched) || form.submitted">
                <span *ngIf="representativeName.errors?.required">
                    Name of representative is required
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="representativeRole">Role of representative</label>
            <input type="text" class="form-control" id="representativeRole" ngModel name="representativeRole" [disabled]="fieldsDisabled" required #representativeRole="ngModel">
            <span class="danger" *ngIf="(!representativeRole.valid && representativeRole.touched) || form.submitted">
                <span *ngIf="representativeRole.errors?.required">
                    Role of representative is required
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="email">Email address</label>
            <input type="email" class="form-control" id="email" ngModel name="email" pattern="[^\s@]+@[^\s@]+\.[^\s@]+$" required #email="ngModel" [disabled]="fieldsDisabled">
            <span class="danger" *ngIf="(!email.valid && email.touched) || form.submitted">
                <span *ngIf="email.errors?.required">
                    Email is required
                </span>
                <span *ngIf="email.errors?.pattern">
                    Email should be filled correctly
                </span>
            </span>
        </div>
        <div class="mb-2 text-left">
            <label for="website">Website</label>
            <input type="text" class="form-control" id="website" ngModel name="website" [disabled]="fieldsDisabled">

        </div>
        <div class="mb-2 text-left">
            <label for="linkedIn">LinkedIn Company Profile</label>
            <input type="text" class="form-control" id="linkedIn" ngModel name="linkedIn" [disabled]="fieldsDisabled">
        </div>



        <div class="mb-4 text-left">
            <label>Connect account:</label>
            <div>
                <button class="btn btn-light social-button" [disabled]="fieldsDisabled" (click)="logInAsMicrosoft()" type="button">
                    <img src="../../../assets/microsoft-logo.svg"
                        alt="">
                    <span class="spinner-grow spinner-grow-sm mx-3"
                        role="status"
                        aria-hidden="true"
                        *ngIf="ms_loginLoading"></span>
                </button>

                <button class="btn btn-light social-button" [disabled]="fieldsDisabled" (click)="logInAsGoogle()" type="button">
                    <img src="../../../assets/google-logo.svg"
                        style="height: 23px;"
                        alt="">
                    <span class="spinner-grow spinner-grow-sm mx-3"
                        role="status"
                        aria-hidden="true"
                        *ngIf="g_loginLoading"></span>
                </button>
            </div>      
        </div>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="registrationLoading">
        Sign up
        <span class="spinner-grow spinner-grow-sm mx-3"
              role="status"
              aria-hidden="true"
              *ngIf="registrationLoading"></span>
    </button>

    <div class="log-in mt-4">
        <span>
        Already have an account? 
        <a href="/login"><b>Log in</b></a>
        </span>
    </div>

</form>