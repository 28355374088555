<!-- 
<div *ngIf="!surveyLoading" class="my-2">
    <button class="btn btn-success" (click)="saveAnswers()" [disabled]="saveAnswersLoading">
        Save Answers
        <span class="spinner-grow spinner-grow-sm mx-3" role="status" aria-hidden="true" *ngIf="saveAnswersLoading"></span>
    </button>
</div> -->

<div class="survey-container contentcontainer codecontainer">
    <div id="surveyElement"></div>
</div>

<div *ngIf="surveyLoading">
    <app-spinner></app-spinner>
</div>
<!-- 
{{surveyLoading}}
{{riskScores.length}}
{{!!riskScores.length}} 
-->
<div class="risk-score" *ngIf="!!riskScores.length">
    <h3>Your risk scores:</h3>
    <div *ngFor="let riskScore of riskScores">
        <span>{{riskScore.groupName}}: <span class="active">{{riskScore.riskScore}}</span></span> 
    </div>
    <div  class="mt-2">
        <b>Total: <span class="active">{{totalRiskScore}}</span></b> 
    </div>

</div>

<!-- <button (click)="logger()">log</button> -->