import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MsSigninService } from '../services/ms-signin.service';


@Injectable()
export class MsalAuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if ((req.url == MsSigninService.AUTHENTICATE_MS_ENDPOINT) || (req.url == MsSigninService.REGISTER_USER_MS_ENDPOINT)) {
            const tkn = req.headers.get("authorization")
            if (tkn) {
                const modifiedReq = req.clone({
                    headers: req.headers.set('X-Custom-Authorization', tkn)
                })
                return next.handle(modifiedReq);
            }
        }
        return next.handle(req);
    }
}